import React from "react"

interface Props {
  title: JSX.Element
  description: JSX.Element
  image: JSX.Element
}

const HeroUnit = ({title, description, image }: Props) => {
  return <div className="hero-unit">
    <div className="content content-glow">
      <h1>
        {title}
      </h1>
      <div className="description">
        {description}
      </div>
    </div>

    {image && <div className="image">
      {image}
    </div>}
  </div>
}

export default HeroUnit