import "./archive-parent.css"

import React from "react"

import { Link, graphql } from "gatsby"
import Layout from "@/templates/layout"
import Seo from "@/components/seo"

import HeroUnit from "@/components/hero-unit"
import { GatsbyImage } from "gatsby-plugin-image"
import { Page } from "@/components/page"


export function PageCard (props: { page: Page }) {
  const { page } = props
  const image = {
    data: page.featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData,
    alt: page.featuredImage?.node?.alt || ``,
  }
  const uri = page.uri.endsWith("/") ? page.uri : `${page.uri}/`

  return <div className="page-card">
    <Link className="page-card-info" to={uri} itemProp="url">
      <div className="page-card-top">
        {image?.data && <GatsbyImage className="image" image={image.data} alt={image.alt} />}
      </div>
      <div className="excerpt" dangerouslySetInnerHTML={{__html: page.title }}></div>
    </Link>
  </div>
}

const Archive = (props: any) => {
  const { data } = props
  const category = props.pageContext.category
  const pages = data.pages.nodes

  const heroProps = {
    title: category.name,
    description: category.description,
  }

  return (
    <Layout {...props} title={category.title}>
      <HeroUnit {...heroProps} />
      <ol className="page-cards">
        {pages
          .map((page: Page, i: number) => <PageCard page={page} key={i} />)}
      </ol>
    </Layout>
  )
}

export default Archive

export const pageQuery = graphql`
query($uriRegex: String!) {
  pages: allWpPage(filter: {uri: {regex: $uriRegex }}) {
    nodes {
      ...PageInformation
    }
  }
}
`
